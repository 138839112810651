import { getConfig } from '../config';

interface GetLaunchUrlInput {
  incomeChannel: string;
  encounterId: string;
  shoulduUseuniquePerAppLaunchId: boolean;
}

export function generateLaunchId({
  incomeChannel,
  encounterId,
  shoulduUseuniquePerAppLaunchId,
}: GetLaunchUrlInput): string {
  const { service } = getConfig();
  if (!shoulduUseuniquePerAppLaunchId) {
    return `${incomeChannel}:${encounterId}`;
  }
  return btoa(`${incomeChannel}:${encounterId}:${service}`);
}
