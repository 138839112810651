import React, { FC } from 'react';
import './loading.less';

const SimpleLoading: FC = () => (
  <div className="loading-page">
    <img src="https://static.getvim.com/img/dotsLoader.svg" alt="loading logo" />
  </div>
);

export default SimpleLoading;
