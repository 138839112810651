import { internalApi } from '../../api'; // Import the authenticateAgent function from the Clover API

interface PubSubServiceInitResponce {
  token: string;
  incomeChannel: string;
  agentId: string;
  config: any;
  organization: {
    cipherKey: string;
    tin: string;
    slug: string;
  };
}

export async function pubSubServiceInit({
  userId,
}: {
  userId: string;
}): Promise<PubSubServiceInitResponce> {
  const agentId = window.btoa(userId);
  const agentResponse = await internalApi.cloverAssistBffApi.agentAuthenticate({
    id: agentId,
  });

  return { agentId, ...agentResponse };
}
