import { BaseInternalApi } from '../baseApi';
import {
  AgentAuthenticateInput,
  AgentAuthenticateResponse,
  CheckEligibilityInput,
  CheckEligibilityResponse,
  GetLaunchUrlInput,
} from './types';
import { createLogger } from '../../utils';
import { getConfig } from '../../config';

const logger = createLogger('CloverAssistBffApi');

export class CloverAssistBffApi extends BaseInternalApi {
  constructor() {
    const { VIM_BFF_BASE_URL } = getConfig();
    super('cloverAssistBffApi', `${VIM_BFF_BASE_URL}`);
  }

  public async checkEligibility(
    input: CheckEligibilityInput,
  ): Promise<CheckEligibilityResponse | undefined> {
    try {
      const httpClient = this.getHttpClient();
      const { data } = await httpClient.post('/clover/eligibility', input, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return { patientToken: data['patientToken'], eligible: data['eligible'] };
    } catch (error) {
      logger.error('Checking eligibility in clover-bff failed', {
        error: error,
      });
      return;
    }
  }

  public async getLaunchUrl(input: GetLaunchUrlInput): Promise<string | undefined> {
    try {
      const httpClient = this.getHttpClient();
      const { data } = await httpClient.post('/clover/launch-url', input, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return data?.launchUrl as string;
    } catch (error) {
      logger.error('Getting launchUrl from clover-bff failed', {
        error: error,
      });
      return;
    }
  }

  public async agentAuthenticate(
    input: AgentAuthenticateInput,
  ): Promise<AgentAuthenticateResponse> {
    const httpClient = this.getHttpClient();
    const { data } = await httpClient.post('/agent/authenticate', input, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return data;
  }
}
