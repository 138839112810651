import { VimSDKProvider } from '@getvim/internal-vim-os-sdk/react';
import '@getvim/atomic-ui/assets/styles/main.less';
import { ThemeVariablesWrapper, themes } from '@getvim/components-hooks-use-theme';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { GlobalStateProvider } from './stores/GlobalStore';
import { FeatureFlagProvider } from '@getvim/feature-flags-react';
import { RequiredStateWrapper } from './components/RequiredStateWrapper';
import { featureFlagsClient } from './logic/featureFlags';
import App from './components/App';
import './index.less';

const AppSdkWrapper = () => {
  return (
    <VimSDKProvider>
      <ThemeVariablesWrapper theme={themes.vim}>
        <FeatureFlagProvider featureFlagsClient={featureFlagsClient}>
          <GlobalStateProvider>
            <RequiredStateWrapper>
              <App />
            </RequiredStateWrapper>
          </GlobalStateProvider>
        </FeatureFlagProvider>
      </ThemeVariablesWrapper>
    </VimSDKProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<AppSdkWrapper />);
