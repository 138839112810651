import { VimConnectGqlClient } from '@getvim/components-vim-connect-gql-client';
import { ScopedLogger } from '@getvim/vim-connect-logger';
import axios, { AxiosInstance } from 'axios';
import { createLogger } from '../utils';

interface InternalApiConfig {
  shouldRetry: boolean;
}

const RETRIES_COUNT = 3;
const DEFAULT_CONFIG: InternalApiConfig = {
  shouldRetry: true,
};

export class BaseInternalApi {
  private gqlClient: VimConnectGqlClient | undefined;
  private httpClient: AxiosInstance | undefined;
  protected accessToken!: string;
  private logger: ScopedLogger;

  constructor(
    name: string,
    private url: string,
    private config: InternalApiConfig = DEFAULT_CONFIG,
  ) {
    this.logger = createLogger(name);
  }

  protected getGqlClient(): VimConnectGqlClient {
    return this.gqlClient ?? this.setGqlClient();
  }

  protected getHttpClient() {
    return this.httpClient ?? this.setHttpClient();
  }

  public setAccessToken(accessToken: string): void {
    this.accessToken = accessToken;
  }

  private setGqlClient(): VimConnectGqlClient {
    this.gqlClient = new VimConnectGqlClient({
      apiUrl: this.url,
      accessTokenCallback: () => this.accessToken,
      retryOnNetworkError: this.config.shouldRetry,
      retryOptions: {
        delay: {
          initial: 1000,
          max: 2500,
          jitter: true,
        },
        attempts: (count, operation, error) => {
          this.logger.warning('Retrying graphql request due network error', {
            attempt: count,
            error,
            operation,
          });
          return count < RETRIES_COUNT;
        },
      },
    });
    return this.gqlClient;
  }

  private setHttpClient(): AxiosInstance {
    this.httpClient = axios.create({
      baseURL: this.url,
      withCredentials: true,
      headers: {
        Authorization: this.accessToken,
      },
    });
    // TODO: decide if we need to enhance our httpClient

    //    this.httpClient = new VimConnectGqlClient({
    //     apiUrl: this.url,
    //     accessTokenCallback: () => this.accessToken,
    //     retryOnNetworkError: this.config.shouldRetry,
    //     retryOptions: {
    //       delay: {
    //         initial: 1000,
    //         max: 2500,
    //         jitter: true,
    //       },
    //       attempts: (count, operation, error) => {
    //         this.logger.warning('Retrying graphql request due network error', {
    //           attempt: count,
    //           error,
    //           operation,
    //         });
    //         return count < RETRIES_COUNT;
    //       },
    //     },
    //   });
    return this.httpClient;
  }
}
