import type { EHR } from 'vim-os-js-browser/types';
import { internalApi } from '../../api';
import { CheckEligibilityInput, CheckEligibilityResponse } from '../../api/assist-bff/types';
import { FullOrganization } from '../../types';

const eligibility = async (input: {
  patient: EHR.Patient & { token: string };
  organization: FullOrganization;
  ehrUsername: string;
}): Promise<CheckEligibilityResponse | undefined> => {
  if (input) {
    const formattedInput = formatRequest(input);
    if (formattedInput) {
      return await internalApi.cloverAssistBffApi.checkEligibility(formattedInput);
    }
  }
};

const formatRequest = (input: {
  patient: EHR.Patient & { token: string };
  organization: FullOrganization;
  ehrUsername: string;
}): CheckEligibilityInput | undefined => {
  const { patient, organization, ehrUsername } = input;

  if (!patient.demographics) {
    return;
  }

  return {
    patient: {
      insurance: {
        ehrInsurance: patient.insurance?.ehrInsurance,
      },
      address: {
        addressLine1: patient.address?.address1,
        city: patient.address?.city,
        state: patient.address?.state,
        zipCode: patient.address?.zipCode,
      },
      vimPatientId: patient.identifiers?.vimPatientId,
      memberId: patient.insurance?.memberId,
      demographics: {
        birthDate: patient.demographics.dateOfBirth,
        firstName: patient.demographics.firstName,
        lastName: patient.demographics.lastName,
        gender: patient.demographics?.gender,
      },
    },
    organization: {
      id: `${organization?.id}`,
      tin: organization?.tin,
      ehrUsername: ehrUsername,
    },
  };
};

export { eligibility };
