export interface Config {
  VIM_SHOULD_CHECK_INSURANCE: boolean;
  VIM_BFF_BASE_URL: string;
  service: string;
}

function getShouldCheckInsurance(VIM_SHOULD_CHECK_INSURANCE): boolean {
  // true if not ser or "true" else false
  return !VIM_SHOULD_CHECK_INSURANCE || VIM_SHOULD_CHECK_INSURANCE === 'true';
}

export function getConfig(): Config {
  const { VIM_SHOULD_CHECK_INSURANCE, VIM_BFF_BASE_URL, service } =
    (window as any).$vim_environment || {};

  return {
    VIM_BFF_BASE_URL: VIM_BFF_BASE_URL || '/clover-assist-bff/api/v1',
    VIM_SHOULD_CHECK_INSURANCE: getShouldCheckInsurance(VIM_SHOULD_CHECK_INSURANCE),
    service: service || 'clover-assist',
  };
}
