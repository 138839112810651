import {
  useApi,
  useAppState,
  useResourcesWithPII,
  useVimUserMetadata,
} from '@getvim/internal-vim-os-sdk/react';
import type { EHR } from 'vim-os-js-browser/types';
import { useEffect, useState } from 'react';
import { useGlobalState } from '../stores/GlobalStore';
import { GlobalStateActionType } from '../stores/globalStore.types';
import { Screens, FullOrganization } from '../types';
import { createLogger } from '../utils';
import { ActivationStatus } from '@getvim/internal-vim-os-sdk/types';
import {
  PubnubService,
  PubsubReceivedStatusEvent,
  PubsubReceivedMessageEvent,
} from '@getvim/vim-connect-pubsub';
import { logic } from '../logic';
import { CheckEligibilityResponse } from '../api/assist-bff/types';
import { useFeatureFlag } from '@getvim/feature-flags-react';
import { FlagOptions } from '@getvim/feature-flags';
import { patientInsuranceValidation } from '../utils/patientInsuranceValidation';
import { getConfig } from '../config';
import { generateLaunchId } from '../utils/generateLaunchId';

const logger = createLogger('useEhrStateHandlers');

let pubsubService;

const uniquePerAppLaunchIdflagOptions: FlagOptions<boolean> = {
  flagName: 'clover-assist.shouldUseUniquePerAppLaunchId',
  defaultValue: false,
};

/**
 * A custom hook to handle ehr state reactive logic
 */
const useEhrStateHandlers = () => {
  const { dispatch, state } = useGlobalState();
  const { ehrState } = useResourcesWithPII();
  const { user, organization } = useVimUserMetadata();
  const [fullOrganization, setFullOrganization] = useState<FullOrganization>();

  const [eligibility, setEligibility] = useState<CheckEligibilityResponse>();

  const [pubNubConfig, setPubNubConfig] = useState<any>();

  const { isAppOpen } = useAppState();
  const { setActivationStatus, autoPopup, resourceUpdate } = useApi();

  const [shoulduUseuniquePerAppLaunchId] = useFeatureFlag(uniquePerAppLaunchIdflagOptions);

  const { VIM_SHOULD_CHECK_INSURANCE, service: serviceName } = getConfig();

  const encounter = ehrState.encounter as EHR.Encounter & {
    token: string;
    loading?: boolean;
  };
  const patient = ehrState.patient as EHR.Patient & { token: string };
  useEffect(() => {
    if (isAppOpen) {
      onOpen()
        .then(() => {
          logger.info('App opened', { noPHI: true });
        })
        .catch((error) => {
          logger.error('Failed to open app', { error, noPHI: true });
        });
    } else {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAppOpen]);

  // ----------------------------------------
  // Patient Handler
  // ----------------------------------------
  useEffect(() => {
    (async () => {
      if (patient) {
        const patientContext = {
          patientId: patient?.identifiers?.vimPatientId,
          insurance: patient?.insurance?.ehrInsurance,
        };
        logger.info('Patient in context', { noPHI: true, ...patientContext });
        if (!user?.identifiers || !fullOrganization || !fullOrganization?.id) {
          logger.info('Mandatory information was not received', { noPHI: true, ...patientContext });
          return;
        }
        logger.info('Before checking eligibility', {
          noPHI: true,
          VIM_SHOULD_CHECK_INSURANCE,
          ...patientContext,
        });
        if (!patientInsuranceValidation(VIM_SHOULD_CHECK_INSURANCE, patient)) {
          logger.info('Patient insurance is not eligible', {
            noPHI: true,
            ...patientContext,
          });
          return;
        }

        const eligibility = await logic.eligibility({
          patient,
          organization: fullOrganization,
          ehrUsername: user.identifiers.ehrUsername,
        });

        logger.info('Got eligibility response', {
          eligibility: eligibility?.eligible,
          noPHI: true,
          ...patientContext,
        });

        if (!eligibility || !eligibility?.eligible) {
          logger.info('Patient is not eligible', { noPHI: true, ...patientContext });
          return;
        }

        setEligibility(eligibility);
        logger.info('Got eligibility', { noPHI: true, ...patientContext });
      } else {
        setActivationStatus(ActivationStatus.DISABLED);
        setEligibility(undefined);
      }
    })().catch((error) => {
      logger.error('Failed to check eligibility', { error });
      logger.error('Failed to check eligibility', { message: error.message, noPHI: true });
    });
  }, [fullOrganization, patient, user, setActivationStatus, VIM_SHOULD_CHECK_INSURANCE]);

  // New PubSub subscription implementation
  useEffect(() => {
    (async () => {
      if (!fullOrganization || !pubNubConfig) {
        return;
      }
      if (!encounter || !eligibility?.eligible) {
        logger.info('Start deleting PubSub service', { noPHI: true });
        if (pubsubService) {
          await pubsubService.pubnub.unsubscribeAll();
          pubsubService = undefined;
        }
        return;
      }
      logger.info('Start initiation PubSub service', {
        noPHI: true,
        encounterId: encounter.identifiers?.ehrEncounterId,
      });
      const { incomeChannel, token, config, agentId } = pubNubConfig;
      pubsubService = new PubnubService({
        uuid: agentId,
        authKey: token,
        ...config,
      });
      pubsubService.addListener({
        status: (event: PubsubReceivedStatusEvent) => {
          logger.info('Received PubSub status', { event });
        },
        message: async (event: PubsubReceivedMessageEvent) => {
          logger.info('Received new PubSub message', {
            noPHI: true,
            encounterId: encounter.identifiers?.ehrEncounterId,
          });
          const { message } = event;
          const decryptMessage = pubsubService?.decrypt({
            cipherKey: fullOrganization.cipherKey!,
            secureText: `${message}`,
          });
          const { responseChannel, actionData } = decryptMessage;
          const encrypted = pubsubService?.encrypt({
            cipherKey: fullOrganization.cipherKey!,
            clearText: JSON.stringify({ actionId: actionData?.actionId }),
          });
          if (shoulduUseuniquePerAppLaunchId && actionData?.data?.data?.service !== serviceName) {
            return;
          }
          logger.info('Got encrypted PubSub message', {
            noPHI: true,
            encounterId: encounter.identifiers?.ehrEncounterId,
            actionId: actionData?.actionId,
          });
          await pubsubService?.publish({
            channel: responseChannel,
            message: { encrypted: encrypted!, orgSlug: fullOrganization.slug },
          });
          try {
            const writebackData = actionData.data;
            logger.info('Before start writeback', {
              actionDataEncounterId: writebackData.data?.encounterId,
              encounterId: encounter.identifiers?.ehrEncounterId,
              noPHI: true,
            });

            if (writebackData?.data?.encounterId != encounter?.identifiers?.ehrEncounterId) {
              logger.warning('EncounterId is not match', {
                actionData: writebackData.data?.encounterId,
                encounterId: encounter?.identifiers?.ehrEncounterId,
                noPHI: true,
              });
              return;
            }
            if (!writebackData.data?.diagnoses) {
              return;
            }
            const updateBuilder = resourceUpdate.encounterBuilder();
            updateBuilder.updateAssessment({ diagnosisCodes: writebackData.data?.diagnoses });

            await updateBuilder.commit();
            logger.info('Assesment successfully updated', {
              actionId: actionData?.actionId,
              encounterId: encounter?.identifiers?.ehrEncounterId,
              noPHI: true,
            });
          } catch (error) {
            logger.error('Failed to update encounter', {
              actionId: actionData?.actionId,
              encounterId: encounter?.identifiers?.ehrEncounterId,
              noPHI: true,
            });
            logger.error('Failed to update encounter', { actionId: actionData?.actionId, error });
          }
        },
      });
      logger.info('Subscribing to PubSub', {
        incomeChannel,
        noPHI: true,
        encounterId: encounter?.identifiers?.ehrEncounterId,
      });
      pubsubService.subscribe({ channels: [incomeChannel] });
    })();

    return () => {
      pubsubService?.pubnub.unsubscribeAll();
      pubsubService = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encounter, eligibility, pubNubConfig, fullOrganization]);

  useEffect(() => {
    (async () => {
      if (user?.identifiers && !pubsubService && organization?.identifiers) {
        logger.info('Start Initializing pubsubService service', {
          noPHI: true,
          organizationName: organization?.identifiers.name,
          organizationId: organization?.identifiers.id,
        });
        const {
          token,
          config,
          organization: fullOrganization,
          incomeChannel,
          agentId,
        } = await logic.pubSubServiceInit({
          userId: user.identifiers.vimUserID,
        });
        setPubNubConfig({ incomeChannel, token, config, agentId });

        setFullOrganization({
          ...organization.identifiers,
          ...fullOrganization!,
        });
        logger.info('PubsubService service activated', {
          noPHI: true,
          organizationName: organization?.identifiers.name,
          organizationId: organization?.identifiers.id,
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, organization]);

  // ----------------------------------------
  // Encounter Handler
  // ----------------------------------------
  useEffect(() => {
    (async () => {
      if (encounter) {
        logger.info('Encounter in context', {
          noPHI: true,
          encounterId: encounter.identifiers?.ehrEncounterId,
        });

        if (!eligibility) {
          logger.info('There is no eligibility info', {
            noPHI: true,
            encounterId: encounter.identifiers?.ehrEncounterId,
          });
          setActivationStatus(ActivationStatus.DISABLED);
          return;
        }

        setActivationStatus(ActivationStatus.ENABLED);
        autoPopup();
        logger.info('App acivated', {
          noPHI: true,
          encounterId: encounter.identifiers?.ehrEncounterId,
        });
      } else {
        setActivationStatus(ActivationStatus.DISABLED);
      }
    })();
  }, [encounter, eligibility, autoPopup, setActivationStatus]);

  const onOpen = async () => {
    dispatch({
      type: GlobalStateActionType.SET_SCREEN,
      payload: {
        screen: Screens.Loading,
      },
    });

    if (!user?.identifiers || !fullOrganization?.tin || !fullOrganization?.id || !pubNubConfig) {
      logger.warning('Mandatory information was not received', {
        noPHI: true,
      });
      setActivationStatus(ActivationStatus.DISABLED);
      return;
    }
    const { incomeChannel } = pubNubConfig;
    const launchId = generateLaunchId({
      incomeChannel,
      encounterId: `${encounter?.identifiers?.ehrEncounterId}`,
      shoulduUseuniquePerAppLaunchId,
    });
    logger.info('After setup launchId', {
      encounterId: encounter.identifiers?.ehrEncounterId,
      launchId,
      noPHI: true,
      shoulduUseuniquePerAppLaunchId,
    });
    const launchUrl = await logic.launchUrl({
      eligibility: eligibility!,
      launchId,
      organization: { tin: fullOrganization.tin, id: fullOrganization.id },
      encounter,
      ehrUsername: user.identifiers.ehrUsername,
      vimUserID: user.identifiers.vimUserID,
    });

    if (!launchUrl) {
      logger.error('Failed to get launchUrl', {
        noPHI: true,
        encounterId: encounter.identifiers?.ehrEncounterId,
      });
      return dispatch({
        type: GlobalStateActionType.SET_SCREEN,
        payload: {
          url: null,
          screen: Screens.CloverAssist,
        },
      });
      return;
    }

    logger.info('Got launchUrl', {
      noPHI: true,
      encounterId: encounter.identifiers?.ehrEncounterId,
      launchId,
    });

    dispatch({
      type: GlobalStateActionType.SET_SCREEN,
      payload: {
        url: launchUrl,
        screen: Screens.CloverAssist,
      },
    });
  };

  const onClose = () => {
    dispatch({ type: GlobalStateActionType.SET_SCREEN, payload: { screen: Screens.None } });
  };

  useEffect(() => {
    switch (state.lastAction?.type) {
      case GlobalStateActionType.ON_OPEN: {
        onOpen();
        break;
      }
      case GlobalStateActionType.ON_CLOSE: {
        onClose();
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.lastAction]);
};

export default useEhrStateHandlers;
