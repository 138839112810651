import React, { createContext, useContext, useReducer } from 'react';
import { Screens } from '../types';
import { GlobalState, GlobalStateAction, GlobalStateActionType } from './globalStore.types';

const initialState: GlobalState = {
  screen: Screens.None,
};

const GlobalStateContext = createContext<{
  state: GlobalState;
  dispatch: React.Dispatch<GlobalStateAction>;
}>({
  state: initialState,
  dispatch: () => {},
});

export const useGlobalState = () => useContext(GlobalStateContext);

const reducer = (state: GlobalState, action: GlobalStateAction): GlobalState => {
  switch (action.type) {
    case GlobalStateActionType.ON_OPEN: {
      return {
        ...state,
        lastAction: {
          type: action.type,
        },
      };
    }
    case GlobalStateActionType.ON_CLOSE: {
      return {
        ...state,
        lastAction: {
          type: action.type,
        },
      };
    }
    case GlobalStateActionType.SET_SCREEN: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export const GlobalStateProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GlobalStateContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalStateContext.Provider>
  );
};
