import { Screens } from '../types';

export type GlobalState = {
  screen: Screens;
  url?: string | null;
  lastAction?: {
    type: GlobalStateActionType;
    timestamp?: number;
  };
};

export enum GlobalStateActionType {
  SET_SCREEN = 'SET_SCREEN',
  ON_OPEN = 'ON_OPEN',
  ON_CLOSE = 'ON_CLOSE',
}

export type GlobalStateAction =
  | {
      type: GlobalStateActionType.ON_OPEN;
    }
  | {
      type: GlobalStateActionType.ON_CLOSE;
    }
  | {
      type: GlobalStateActionType.SET_SCREEN;
      payload: {
        screen: Screens;
        url?: GlobalState['url'];
      };
    };
