import type { EHR } from 'vim-os-js-browser/types';

const patientIncurances = new Set(['cloverhealth', 'clover health', 'clover']);

/**
 * Check if the patient has a valid insurance. Insurance in patientIncurances should be in lowercase.
 */
export function patientInsuranceValidation(
  VIM_SHOULD_CHECK_INSURANCE: boolean,
  patient: EHR.Patient,
) {
  if (!patient?.insurance?.ehrInsurance) {
    return false;
  }
  return VIM_SHOULD_CHECK_INSURANCE
    ? patientIncurances.has(`${patient.insurance.ehrInsurance}`.toLocaleLowerCase())
    : !patientIncurances.has(`${patient.insurance.ehrInsurance}`.toLocaleLowerCase());
}
