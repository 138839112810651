import React, { useCallback, useMemo } from 'react';
import { FlowContextWrapper } from '@getvim/flow-context-provider';
import { Loader } from '@getvim/atomic-ui';
import { useVimAuth } from '@getvim/internal-vim-os-sdk/react';
import { internalApi } from '../api';
import { createLogger } from '../utils';

export const RequiredStateWrapper = ({ children }) => {
  const { accessToken } = useVimAuth();
  const logger = createLogger('RequiredStateWrapper');
  const mandatoryState = useMemo(() => {
    if (accessToken) {
      logger.info('App has received token');
      internalApi.cloverAssistBffApi.setAccessToken(accessToken);
    }

    return {
      accessToken: Promise.resolve(accessToken),
    };
  }, [accessToken, logger]);

  const onError = useCallback(() => {
    logger.error('Failed to fetch state in App RequiredStateWrapper', { mandatoryState });
  }, [logger, mandatoryState]);

  return (
    <FlowContextWrapper loader={<Loader type="dots" />} state={mandatoryState} onError={onError}>
      {children}
    </FlowContextWrapper>
  );
};
