import { ErrorMessageResponseType, SessionContext } from '@getvim/vim-os-sdk-api';
import { HandshakePayload } from '@getvim/vim-os-api';

type AllValuesDefined<T extends object> = { [K in keyof T]: Exclude<T[K], undefined> };

function ifAllValuesDefined<T extends object>(
  obj: T,
): T extends AllValuesDefined<T> ? T : undefined {
  return (
    Object.values(obj).every((value) => value !== undefined) ? obj : undefined
  ) as T extends AllValuesDefined<T> ? T : undefined;
}

export function convertUser(user: HandshakePayload['user']): SessionContext.User {
  // We explicitly select fields to avoid internal fields like `roles`
  return {
    identifiers: ifAllValuesDefined({
      ehrUsername: user.linkedEhrUser,
      vimUserID: user.externalId,
      npi: user.npi,
    }),
    contactInfo: ifAllValuesDefined({
      email: user.email,
    }),
    demographics: ifAllValuesDefined({
      firstName: user.firstName,
      lastName: user.lastName,
    }),
  };
}

export function convertOrganization(
  organization: HandshakePayload['organization'],
): SessionContext.SessionContext['organization'] {
  // We explicitly select fields to avoid internal fields like `tin`
  return {
    identifiers: ifAllValuesDefined({
      name: organization.name,
      id: organization.id,
      tin: organization.tin,
    }),
  };
}
export class SessionContextApi implements SessionContext.SessionContext {
  private idToken?: string;

  constructor({
    handshakePayload,
    tokensResponse,
  }: {
    handshakePayload: HandshakePayload;
    tokensResponse?: { idToken: string };
  }) {
    this.organization = convertOrganization(handshakePayload.organization);
    this.user = convertUser(handshakePayload.user);
    this.ehrType = handshakePayload.ehrVendor;
    this.sessionId = handshakePayload.userSessionId;
    this.idToken = tokensResponse?.idToken;
  }

  user: SessionContext.SessionContext['user'];
  organization: SessionContext.SessionContext['organization'];
  sessionId: SessionContext.SessionContext['sessionId'];
  ehrType: SessionContext.SessionContext['ehrType'];

  async getIdToken(): Promise<SessionContext.GetIdTokenResponse> {
    if (!this.idToken) {
      throw {
        type: ErrorMessageResponseType.preconditions_error,
        data: `It appears that the ID token is missing. Please ensure that application authentication is enabled and verify that the token endpoint correctly returns the VimOs authentication payload. `,
      };
    }
    return { idToken: this.idToken };
  }
}
