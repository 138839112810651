export enum Screens {
  None = 'none',
  Loading = 'loading',
  CloverAssist = 'clover-assist',
}
export interface FullOrganization {
  id: number;
  slug: string;
  tin: string;
  cipherKey: string;
}
